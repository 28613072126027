<template>
	<v-tooltip top content-class="custom-top-tooltip">
		<template v-slot:activator="{ on, attrs }">
			<v-icon :small="small" class="mx-2" :class="contentClass" v-bind="attrs" v-on="on"
				>mdi-progress-question</v-icon
			>
		</template>
		<span>
			<slot name="text"></slot>
		</span>
	</v-tooltip>
</template>

<script>
export default {
	name: "tooltip-question",
	title: "Tooltip Question",
	props: {
		small: {
			type: Boolean,
			default: true,
		},
		contentClass: {
			type: String,
			default: null,
		},
	},
};
</script>
