<template>
	<div v-if="start_date">
		<v-menu
			ref="datepicker"
			v-model="datepicker"
			:close-on-content-click="false"
			transition="scale-transition"
			offset-y
			max-width="290px"
			min-width="auto"
		>
			<template v-slot:activator="{ on, attrs }">
				<v-text-field
					:id="id"
					:label="label"
					:loading="loading"
					:disabled="disabled"
					:rules="rules"
					readonly
					outlined
					class="pt-0"
					:class="{ ...contentClass, 'mt-3': !hideTopMargin }"
					v-model="dateFormatted"
					:placeholder="btxPlaceholder"
					hide-details
					:clearable="clearable"
					v-on:click:clear="$emit('click:clear', true)"
					:prepend-inner-icon="hidePrefixIcon ? '' : 'mdi-calendar'"
					v-bind="attrs"
					v-on:blur="date = parseDate(dateFormatted)"
					v-on="on"
				></v-text-field>
			</template>
			<v-date-picker
				v-model="date"
				no-title
				:min="minDate"
				:max="maxDate"
				:disabled="disabled"
				:readonly="readonly"
				@input="datepicker = false"
				:show-current="show_current"
			></v-date-picker>
		</v-menu>
	</div>
</template>

<script>
export default {
	name: "date-picker",
	model: {
		prop: "value",
		event: "input",
	},
	props: {
		value: {
			type: [Date, String],
			default: null,
		},
		hidePrefixIcon: {
			type: Boolean,
			default: false,
		},
		minDate: {
			type: [Date, String],
			default: null,
		},
		hideTopMargin: {
			type: Boolean,
			default: false,
		},
		maxDate: {
			type: [Date, String],
			default: null,
		},
		rules: {
			type: [Array, Object],
			default: () => {
				return [];
			},
		},
		placeholder: {
			type: String,
			default: "Date",
		},
		id: {
			type: String,
			default: null,
		},
		label: {
			type: String,
			default: null,
		},
		contentClass: {
			type: Object,
			default: null,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		clearable: {
			type: Boolean,
			default: false,
		},
		readonly: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			date: null,
			datepicker: false,
			dateFormatted: null,
			show_current: null,
			start_date: true,
		};
	},
	watch: {
		minDate(param) {
			const _this = this;
			if (!_this.readonly) {
				_this.date = null;
			}
			if (param) {
				_this.show_current = null;
				_this.start_date = false;
				setTimeout(function () {
					_this.start_date = true;
					_this.show_current = _this.formatDateRaw(param);
				}, 200);
			}
		},
		maxDate() {
			if (!this.readonly) {
				this.date = null;
			}
		},
		date() {
			this.emitValue();
		},
		value() {
			this.date = this.value;
		},
	},
	methods: {
		emitValue() {
			if (this.date) {
				this.dateFormatted = this.formatDate(this.date);
				this.$emit("input", this.date);
				this.$emit("change", this.date);
			} else {
				this.dateFormatted = null;
				this.$emit("input", null);
				this.$emit("change", null);
			}
		},
		formatDate(date) {
			if (!date) return null;
			const [year, month, day] = date.split("-");
			return `${day}/${month}/${year}`;
		},
		parseDate(date) {
			if (!date) return null;
			const [day, month, year] = date.split("/");
			return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
		},
	},
	computed: {
		btxPlaceholder() {
			return this.placeholder + " [DD/MM/YYYY]";
		},
	},
	mounted() {
		this.date = this.value;
		if (this.minDate) {
			this.show_current = this.formatDateRaw(this.minDate);
		}
		this.$nextTick(() => {
			this.emitValue();
		});
	},
};
</script>
