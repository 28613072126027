<template>
	<v-textarea
		:id="id"
		ref="input-text-area"
		:label="label"
		:loading="loading"
		:disabled="disabled"
		:hide-details="hideDetails"
		outlined
		:rows="rows"
		:rules="[
			(v) => (v && !hideDetails ? v.length <= 250 : true) || `${placeholder} maximum 250 characters`,
			(v) =>
				(v && !hideDetails ? v.split(/\r|\r\n|\n/).length <= 10 : true) ||
				`${placeholder} maximum 10 Line`,
			...rules,
		]"
		class="pt-0"
		:class="{ 'mt-3': !dense }"
		v-model="textareainput"
		:placeholder="placeholder"
		v-on:change="$emit('change', textareainput)"
		v-on:keyup="$emit('keyup', textareainput)"
		v-on:keypress="$emit('keypress', textareainput)"
		v-on:blur="$emit('blur', textareainput)"
		auto-grow
		:counter="counter"
		:counter-value="(v) => (v ? v.length : 0)"
		:hint="`Max character limit ${counter}`"
		persistent-hint
	>
	</v-textarea>
</template>
<script>
// import { toString } from "lodash";

export default {
	name: "textarea-input",
	model: {
		prop: "value",
		event: "input",
	},
	props: {
		value: {
			type: String,
			default: "",
		},
		placeholder: {
			type: String,
			default: null,
		},
		id: {
			type: String,
			default: null,
		},
		counter: {
			type: [String, Number],
			default: 250,
		},
		rows: {
			type: Number,
			default: 3,
		},
		rules: {
			type: [Array, Object],
			default: () => {
				return [];
			},
		},
		label: {
			type: String,
			default: null,
		},
		dense: {
			type: Boolean,
			default: false,
		},
		hideDetails: {
			type: Boolean,
			default: false,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			textareainput: null,
		};
	},
	watch: {
		value() {
			this.textareainput = this.value;
		},
		textareainput() {
			this.$emit("input", this.textareainput);
		},
	},
	/* methods: {
		stopTypeing(event) {
			const _word_count = toString(this.textareainput).trim().split(" ").length;
			if (!this.hideDetails) {
				if (this.counter == _word_count) {
					if (event.keyCode == 32) {
						event.preventDefault();
					}
				}
				if (this.counter < _word_count) {
					event.preventDefault();
				}
			}
		},
	}, */
	mounted() {
		this.textareainput = this.value;
	},
};
</script>
